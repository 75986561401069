import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  Paper,
  CircularProgress,
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Delete } from '@mui/icons-material';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/pt-br';
import {
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from 'recharts';

moment.locale('pt-br');

const DashboardFinanceiro = () => {
  const [accordionExpanded, setAccordionExpanded] = useState(true); // Controle do Accordion
  const [periods, setPeriods] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [summary, setSummary] = useState([]);
  const [loading, setLoading] = useState(false);
  const [originalData, setOriginalData] = useState([]);

  const [includeAffiliateWithdrawals, setIncludeAffiliateWithdrawals] = useState(true);
  const [includeManualDeposits, setIncludeManualDeposits] = useState(false);
  const [includeManualWithdrawals, setIncludeManualWithdrawals] = useState(false);

  const formatNumber = (num) =>
    new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(num || 0);

  const formatPeriodName = (period) => {
    if (!period || !period.start || !period.end) {
      return 'Período Removido';
    }
    return `${period.start.format('DD/MM/YYYY')} à ${period.end.format('DD/MM/YYYY')}`;
  };

  const setupDefaultPeriods = () => {
    const today = moment();
    const todayClone = today.clone()
    const firstDayOfMonth = today.clone().startOf('month');
    const lastMonthStart = firstDayOfMonth.clone().subtract(1, 'month');
    const lastMonthEnd = todayClone.clone().subtract(1, 'month');

    setPeriods([
      { start: firstDayOfMonth, end: todayClone },
      { start: lastMonthStart, end: lastMonthEnd },
    ]);
  };

  const addPeriod = () => {
    if (periods.length > 0) {
      const lastPeriod = periods[periods.length - 1];
      const newStart = moment(lastPeriod.start).subtract(1, 'month');
      const newEnd = moment(lastPeriod.end).subtract(1, 'month');
      setPeriods([...periods, { start: newStart, end: newEnd }]);
    } else {
      // Caso não exista nenhum período, adiciona o padrão (ontem e hoje)
      const today = moment();
      const todayClone = today.clone()
      setPeriods([{ start: todayClone, end: today }]);
    }
  };

  const removePeriod = (index) => {
    const newPeriods = periods.filter((_, i) => i !== index);
    setPeriods(newPeriods);
  
    const newSummary = summary.filter((_, i) => i !== index);
    setSummary(newSummary);
  
    const newChartData = chartData.map((row) => {
      const updatedRow = { ...row };
      delete updatedRow[`lucroPeriodo${index + 1}`];
      return updatedRow;
    });
    setChartData(newChartData);
  };

  const updatePeriod = (index, field, value) => {
    const newPeriods = [...periods];
    newPeriods[index][field] = value;
    setPeriods(newPeriods);
  };

  const fetchFinanceiroData = async () => {
    try {
      setLoading(true);
      const allData = [];

      for (const period of periods) {
        if (period.start && period.end) {
          const response = await axios.get('/api/financeiro', {
            params: {
              startDate: period.start?.format('YYYY-MM-DD'),
              endDate: period.end?.format('YYYY-MM-DD'),
            },
          });

          const data = response.data.map((entry) => ({
            ...entry,
            dia: moment(entry.dia).format('DD/MM'),
            depositos_clientes_psp: parseFloat(entry.depositos_clientes_psp || 0),
            saques_clientes_psp: parseFloat(entry.saques_clientes_psp || 0),
            depositos_clientes_manual: parseFloat(entry.depositos_clientes_manual || 0),
            saques_clientes_manual: parseFloat(entry.saques_clientes_manual || 0),
            saques_afiliados: parseFloat(entry.saques_afiliados || 0),
          }));

          allData.push(data);
        }
      }

      setOriginalData(allData);
      updateChartAndSummary(allData);

      // Recolher o Accordion após o carregamento
      setAccordionExpanded(false);
    } catch (error) {
      console.error('Erro ao buscar dados financeiros:', error.message);
    } finally {
      setLoading(false);
    }
  };

  const updateChartAndSummary = (allData) => {
    const combinedData = [];
    if (allData.length > 0) {
      const maxDays = Math.max(...allData.map((data) => (Array.isArray(data) ? data.length : 0)));

      for (let i = 0; i < maxDays; i++) {
        const row = { dia: allData[0][i]?.dia || `Dia ${i + 1}` };

        allData.forEach((periodData, periodIndex) => {
          const day = periodData?.[i] || {};
          row[`lucroPeriodo${periodIndex + 1}`] = calculateDayProfit(day);
        });

        combinedData.push(row);
      }
    }

    setChartData(combinedData);
    const summaries = allData.map((data) => calculateSummary(data));
    setSummary(summaries);
  };

  const calculateDayProfit = (day) => {
    let lucro = day.depositos_clientes_psp - day.saques_clientes_psp;

    if (includeManualDeposits) {
      lucro += day.depositos_clientes_manual;
    }

    if (includeManualWithdrawals) {
      lucro -= day.saques_clientes_manual;
    }

    if (includeAffiliateWithdrawals) {
      lucro -= day.saques_afiliados;
    }

    return lucro;
  };

  const calculateSummary = (data) => {
    return data.reduce(
      (acc, cur) => {
        acc.depositos += cur.depositos_clientes_psp;
        acc.saques += cur.saques_clientes_psp;

        if (includeManualDeposits) {
          acc.depositos += cur.depositos_clientes_manual;
        }

        if (includeManualWithdrawals) {
          acc.saques += cur.saques_clientes_manual;
        }

        if (includeAffiliateWithdrawals) {
          acc.saques += cur.saques_afiliados;
        }

        acc.lucro = acc.depositos - acc.saques;
        return acc;
      },
      { depositos: 0, saques: 0, lucro: 0 }
    );
  };

  useEffect(() => {
    if (originalData.length > 0) {
      updateChartAndSummary(originalData);
    }
  }, [includeAffiliateWithdrawals, includeManualDeposits, includeManualWithdrawals]);

  useEffect(() => {
    setupDefaultPeriods();
  }, []);

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom sx={{ marginBottom: '30px' }}>
        Dashboard Financeiro
      </Typography>

      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
          <CircularProgress />
        </Box>
      )}

      {!loading && (
        <>
          {/* Accordion para filtros */}
          <Accordion expanded={accordionExpanded} onChange={() => setAccordionExpanded((prev) => !prev)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Filtros</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ margin: 2 }}>
              <Grid container spacing={2} alignItems="center" justifyContent="center">
                {periods.map((period, index) => (
                  <Grid container spacing={2} key={index} alignItems="center">
                    <LocalizationProvider dateAdapter={AdapterMoment} locale="pt-br">
                      <Grid item xs={12} sm={5} sx={{ marginBottom: '12px' }}>
                        <DatePicker
                          label={`Início Período ${index + 1}`}
                          value={period.start}
                          onChange={(newValue) => updatePeriod(index, 'start', newValue)}
                          renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                      </Grid>
                      <Grid item xs={12} sm={5} sx={{ marginBottom: '12px' }}>
                        <DatePicker
                          label={`Fim Período ${index + 1}`}
                          value={period.end}
                          onChange={(newValue) => updatePeriod(index, 'end', newValue)}
                          renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                      </Grid>
                    </LocalizationProvider>
                    <Grid item xs={12} sm={2} sx={{ marginBottom: '12px' }}>
                      <IconButton color="error" onClick={() => removePeriod(index)}>
                        <Delete />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
                <Grid item xs={12}>
                  <Button variant="outlined" onClick={addPeriod}>
                    Adicionar Período
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button variant="contained" onClick={fetchFinanceiroData}>
                    Aplicar Filtros
                  </Button>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          {/* Checkboxes */}
          <FormGroup row sx={{ marginBottom: 4 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={includeAffiliateWithdrawals}
                  onChange={() => setIncludeAffiliateWithdrawals((prev) => !prev)}
                />
              }
              label="Incluir Saques de Afiliados"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={includeManualDeposits}
                  onChange={() => setIncludeManualDeposits((prev) => !prev)}
                />
              }
              label="Incluir Depósitos Manuais"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={includeManualWithdrawals}
                  onChange={() => setIncludeManualWithdrawals((prev) => !prev)}
                />
              }
              label="Incluir Saques Manuais"
            />
          </FormGroup>

          {/* Resumo */}
          <Grid container spacing={2} sx={{ marginBottom: 4 }}>
            {summary.map((sum, index) => (
              periods[index] && (
              <Grid item xs={12} md={4} key={index}>
                <Paper sx={{ padding: 2 }}>
                  <Typography variant="h6">{`Lucro ${formatPeriodName(periods[index])}`}</Typography>
                  <Typography variant="h4">{formatNumber(sum.lucro)}</Typography>
                  <Typography variant="body1">{`Depósitos: ${formatNumber(sum.depositos)}`}</Typography>
                  <Typography variant="body1">{`Saques: ${formatNumber(sum.saques)}`}</Typography>
                </Paper>
              </Grid>
              )
            ))}
          </Grid>

          {/* Gráfico */}
          <Typography variant="h6">Gráfico Comparativo de Lucros por Dia</Typography>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={chartData}>
              <CartesianGrid stroke="#ccc" />
              <XAxis dataKey="dia" />
              <YAxis />
              <Tooltip formatter={(value) => formatNumber(value)} />
              <Legend />
              {periods.map((period, index) => (
                <Bar
                  key={index}
                  dataKey={`lucroPeriodo${index + 1}`}
                  fill={`hsl(${index * 100}, 70%, 50%)`}
                  name={`Lucro ${formatPeriodName(period)}`}
                >
                  <LabelList
                    dataKey={`lucroPeriodo${index + 1}`}
                    position="top"
                    formatter={(value) => `R$ ${value.toFixed(2)}`}
                    style={{ fill: '#000', fontSize: 12, fontWeight: 'bold' }}
                    offset={5}
                  />
                </Bar>
              ))}
            </BarChart>
          </ResponsiveContainer>
        </>
      )}
    </Box>
  );
};

export default DashboardFinanceiro;
