import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import DashboardFinanceiro from './pages/DashboardFinanceiro';
import DashboardFinanceiroAposta from './pages/DashboardFinanceiroAposta';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<DashboardFinanceiro />} />
        <Route path="/financeiro-aposta" element={<DashboardFinanceiroAposta />} />
      </Routes>
    </Router>
  );
}

export default App;
