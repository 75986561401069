import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Paper,
  CircularProgress,
  IconButton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Delete } from '@mui/icons-material';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/pt-br';
import {
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

moment.locale('pt-br');

const DashboardFinanceiroAposta = () => {
  const [accordionExpanded, setAccordionExpanded] = useState(true);
  const [periods, setPeriods] = useState([]);
  const [loading, setLoading] = useState(false);

  const [chartData, setChartData] = useState({
    geral: [],
    esportes: [],
    cassino: [],
  });

  const [summary, setSummary] = useState({
    geral: [],
    esportes: [],
    cassino: [],
  });

  const formatNumber = (num) =>
    new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(num || 0);

  const formatPeriodName = (period) => {
    if (!period || !period.start || !period.end) {
      return 'Período Removido';
    }
    return `${period.start.format('DD/MM/YYYY')} à ${period.end.format('DD/MM/YYYY')}`;
  };

  const setupDefaultPeriods = () => {
    const today = moment();
    const todayClone = today.clone()
    const firstDayOfMonth = today.clone().startOf('month');
    const lastMonthStart = firstDayOfMonth.clone().subtract(1, 'month');
    const lastMonthEnd = todayClone.clone().subtract(1, 'month');

    setPeriods([
      { start: firstDayOfMonth, end: todayClone },
      { start: lastMonthStart, end: lastMonthEnd },
    ]);
  };

  const addPeriod = () => {
    const lastPeriod = periods[periods.length - 1];
    const newStart = moment(lastPeriod.start).subtract(1, 'month');
    const newEnd = moment(lastPeriod.end).subtract(1, 'month');
    setPeriods([...periods, { start: newStart, end: newEnd }]);
  };

  const removePeriod = (index) => {
    const newPeriods = periods.filter((_, i) => i !== index);
    setPeriods(newPeriods);
  };

  const updatePeriod = (index, field, value) => {
    const newPeriods = [...periods];
    newPeriods[index][field] = value;
    setPeriods(newPeriods);
  };

  const mergeChartData = (allData) => {
    if (allData.length === 0) return [];
  
    const combinedData = [];
    const maxDays = Math.max(...allData.map((periodData) => periodData.length));
  
    for (let i = 0; i < maxDays; i++) {
      const row = {};
  
      allData.forEach((periodData, periodIndex) => {
        const entry = periodData[i] || {}; // Garantir o alinhamento baseado no índice
        const dia = entry.dia || `Dia ${i + 1}`;
  
        if (!row.dia) row.dia = dia; // Definir o dia no eixo X
        row[`lucroPeriodo${periodIndex + 1}`] = entry.total_liquido || 0;
      });
  
      combinedData.push(row);
    }
  
    return combinedData;
  };
  
  const combineDays = (data) => {
    return data.reduce((acc, curr) => {
      const existingDay = acc.find((entry) => entry.dia === curr.dia);
  
      if (existingDay) {
        existingDay.qtd_apostas += curr.qtd_apostas;
        existingDay.total_apostado += curr.total_apostado;
        existingDay.total_liquido += curr.total_liquido;
      } else {
        acc.push({ ...curr });
      }
  
      return acc;
    }, []);
  };
    

  const fetchFinanceiroData = async () => {
    try {
      setLoading(true);

      const geralData = [];
      const esportesData = [];
      const cassinoData = [];
      const summaries = { geral: [], esportes: [], cassino: [] };

      for (const period of periods) {
        const response = await axios.get('/api/financeiro-aposta', {
          params: {
            startDate: period.start.format('YYYY-MM-DD'),
            endDate: period.end.format('YYYY-MM-DD'),
          },
        });

        const periodData = response.data.map((entry) => ({
          dia: moment.utc(entry.dia).format('DD/MM'),
          qtd_apostas: parseFloat(entry.qtd_apostas || 0),
          total_apostado: parseFloat(entry.total_apostado || 0),
          total_liquido: parseFloat(entry.total_liquido || 0),
          modalidade: entry.modalidade,
        }));

        const geral = combineDays(periodData);
        const esportes = periodData.filter((entry) => entry.modalidade !== 'casinoaposta');
        const cassino = periodData.filter((entry) => entry.modalidade === 'casinoaposta');

        geralData.push(geral);
        esportesData.push(esportes);
        cassinoData.push(cassino);

        summaries.geral.push({
          descricao: formatPeriodName(period),
          qtdApostas: geral.reduce((acc, cur) => acc + cur.qtd_apostas, 0),
          totalApostado: geral.reduce((acc, cur) => acc + cur.total_apostado, 0),
          lucro: geral.reduce((acc, cur) => acc + cur.total_liquido, 0),
        });

        summaries.esportes.push({
          descricao: formatPeriodName(period),
          qtdApostas: esportes.reduce((acc, cur) => acc + cur.qtd_apostas, 0),
          totalApostado: esportes.reduce((acc, cur) => acc + cur.total_apostado, 0),
          lucro: esportes.reduce((acc, cur) => acc + cur.total_liquido, 0),
        });

        summaries.cassino.push({
          descricao: formatPeriodName(period),
          qtdApostas: cassino.reduce((acc, cur) => acc + cur.qtd_apostas, 0),
          totalApostado: cassino.reduce((acc, cur) => acc + cur.total_apostado, 0),
          lucro: cassino.reduce((acc, cur) => acc + cur.total_liquido, 0),
        });
      }

      setChartData({
        geral: mergeChartData(geralData),
        esportes: mergeChartData(esportesData),
        cassino: mergeChartData(cassinoData),
      });

      setSummary(summaries);
    } catch (error) {
      console.error('Erro ao buscar dados financeiros:', error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setupDefaultPeriods();
  }, []);

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        Dashboard Financeiro Apostas
      </Typography>

      <Accordion expanded={accordionExpanded} onChange={() => setAccordionExpanded((prev) => !prev)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Filtros</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ margin: 2 }}>
          <Grid container spacing={2} alignItems="center">
            {periods.map((period, index) => (
              <Grid container spacing={2} key={index}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <Grid item xs={12} sm={5} sx={{ marginBottom: '12px' }}>
                    <DatePicker
                      label={`Início Período ${index + 1}`}
                      value={period.start}
                      onChange={(newValue) => updatePeriod(index, 'start', newValue)}
                      renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <DatePicker
                      label={`Fim Período ${index + 1}`}
                      value={period.end}
                      onChange={(newValue) => updatePeriod(index, 'end', newValue)}
                      renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                  </Grid>
                </LocalizationProvider>
                <Grid item xs={12} sm={2}>
                  <IconButton color="error" onClick={() => removePeriod(index)}>
                    <Delete />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
            <Grid item xs={12}>
              <Button variant="outlined" onClick={addPeriod}>
                Adicionar Período
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" onClick={fetchFinanceiroData}>
                Aplicar Filtros
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      {loading ? (
        <CircularProgress />
      ) : (
        ['geral', 'esportes', 'cassino'].map((key) => (
          <Box key={key}>
            <Typography variant="h5" gutterBottom>
              {key.charAt(0).toUpperCase() + key.slice(1)}
            </Typography>
            <Grid container spacing={2} sx={{ marginTop: 2 }}>
              {summary[key].map((sum, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <Paper sx={{ padding: 2 }}>
                    <Typography variant="h6">{sum.descricao}</Typography>
                    <Typography>{`Apostas: ${sum.qtdApostas}`}</Typography>
                    <Typography>{`Total Apostado: ${formatNumber(sum.totalApostado)}`}</Typography>
                    <Typography>{`Lucro: ${formatNumber(sum.lucro)}`}</Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
            <ResponsiveContainer width="100%" height={400}>
              <BarChart data={chartData[key]} barCategoryGap="20%">
                <CartesianGrid stroke="#ccc" />
                <XAxis dataKey="dia" />
                <YAxis />
                <Tooltip formatter={(value) => formatNumber(value)} />
                <Legend />
                {periods.map((period, index) => (
                  <Bar
                    key={index}
                    dataKey={`lucroPeriodo${index + 1}`}
                    fill={`hsl(${index * 60}, 70%, 50%)`}
                    name={formatPeriodName(period)}
                  />
                ))}
              </BarChart>
            </ResponsiveContainer>
          </Box>
        ))
      )}
    </Box>
  );
};

export default DashboardFinanceiroAposta;
